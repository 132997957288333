"use strict";
/**
 * This service is meant to be injected via webpack into the HTML head.
 * This can be useful for enabling plugin, etc that work outside of the react environment
 *
 * Requiring this package anywhere won't do anything at best, and may cause issues at worst.
 *
 * This is included in the bundle via webpack and included in the built `index.html`
 */
// @ts-ignore
function generateServiceWorker() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function () {
            navigator.serviceWorker
                .register('codex-service-worker-2.js')
                .then(function (registration) { return console.log('Worker registration successful', registration.scope); }, function (err) { return console.log('Worker registration failed', err); })
                .catch(function (err) { return console.log(err); });
        });
    }
    else {
        console.log('Service Worker is not supported by browser.');
    }
}
function noIndexOffProd() {
    if (window.location.origin.indexOf('commandercodex') === -1) {
        var link = document.createElement('meta');
        link.name = 'robots';
        link.content = 'noindex';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
}
generateServiceWorker();
noIndexOffProd();
